/*------ 24. login register Page  --------*/
.btn-google{
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
  &:hover{
    background-color: #dc3545;
    border-color: #dc3545;
    color: #fff;
  }
}
.login-register-wrapper {
  .sin-margen{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .titulo-registro{
    font-size: 20px;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 40px;
    text-transform: capitalize;
    transition: all 0.3s ease 0s;
    color: #545482;

  }
  .tab-registro{
    background-color: #909090;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    .nav-item {
      position: relative;

      a {
        border-color: #909090;
        border-width: 1px;
        border-style: solid;
        background-color: #fff;
        padding-block: 6px;

        h4 {
          font-size: 18px;
          font-weight: 500;
          color: #545482;
          text-transform: capitalize;
          transition: all 0.3s ease 0s;
        }
        &:last-child::before {
          display: none;
        }
      }
      a.active{
        background-color: #545482;
        h4 {
        color: #fff;
      }
      } 

      &:before {
        background-color: #454545;
        bottom: 5px;
        content: "";
     
        position: absolute;
        right: -2px;
        transition: all 0.4s ease 0s;
        width: 1px;
      }

      &:last-child {
        &::before {
          display: none;
        }
      }
    }
 
    .titulo-interno{
      background: #545482;
      color: #fff !important;
      font-size: 16px;
      border-radius: 25px;
    }

  }
  
  .login-register-tab-list {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  
    .nav-item {
      position: relative;
      a {
        background: none;
        padding: 0;

        h4 {
          font-size: 20px;
          font-weight: 700;
          margin: 0 20px;
          text-transform: capitalize;
          transition: all 0.3s ease 0s;
        }
        &:last-child::before {
          display: none;
        }
      }
      a.active h4 {
        color: $theme-color;
      }

      &:before {
        background-color: #454545;
        bottom: 5px;
        content: "";
        height: 18px;
        margin: 0 auto;
        position: absolute;
        right: -2px;
        transition: all 0.4s ease 0s;
        width: 1px;
      }

      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }
  .login-form-container {
    background: transparent none repeat scroll 0 0;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    padding: 80px;
    text-align: left;
    @media #{$lg-layout} {
      padding: 40px 50px;
    }
    @media #{$xs-layout} {
      padding: 20px 15px;
    }
    .login-register-form {
      label{
        color: #545482;
        font-weight: 500;
        font-size: 16px;
      }
    
        input {
          background-color: transparent;
          border: 1px solid #e58f2f;
          border-radius: 25px;
          color: #545482;
          font-size: 15px;
          height: 45px;
          margin-bottom: 30px;
          padding: 0 15px;
        }
        input[type="checkbox"] {
          height: 15px;
          margin: 0;
          position: relative;
          top: 1px;
          width: 17px;
        }
        .button-box {
          .login-toggle-btn {
            
            padding: 15px 0 19px;
            label {
              color: #545482;
              font-size: 15px;
              font-weight: 400;
              @media #{$xs-layout} {
                font-size: 14px;
              }
            }
            a {
              color: #545482;
              //float: right;
              font-size: 15px;
              @media #{$xs-layout} {
                font-size: 14px;
              }
              transition: all 0.3s ease 0s;
              &:hover {
                color: $theme-color;
              }
            }
          }
          button {
            background-color: #545482;
            //border: medium none;
            color: #fff;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            line-height: 1;
            padding: 15px 30px;
            //text-transform: uppercase;
            border-radius: 25px;
            border-style: none;
            transition: all 0.3s ease 0s;
            &:hover {
              background-color: #e58f2f;
              color: #fff;
            }
          }
        }
      
      
    }
  }
}

.login-register-area {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}
