/*----------------------------------------*/

/*  1. Template default CSS
/*----------------------------------------*/

/*-- Google Font --*/

@import url("https://fonts.googleapis.com/css?family=Cormorant+Garamond:300,400,500,600,700|Poppins:300,400,500,600,700,800,900|Josefin+Sans:wght@300;400;500;600;700|Abril+Fatface|Great+Vibe|Lobster&display=swap");

/*-- Common Style --*/

*,
*::after,
*::before {
  box-sizing: border-box;
}

.form-check-input {
  border-width: 2px;
  border-color: #694b99;
}

.modal-loading {
  background-color: transparent;
  border: none;
}

.search-icon {
  top: 30%;
  left: 15px
}



.btn-disabled {
  //cursor: not-allowed;
  padding-block: 10px;
  border-radius: 15px;
  font-size: 13px;
  padding-inline: 2px;
  font-weight: 600;
  margin-inline: 2px;
  background-color: rgba(239, 239, 239, 0.3);
  //border-top-color: rgba(16, 16, 16, 0.3);
  border-color: #694b99;
  //background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3)) !important;
  //  color: -internal-light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3)) !important;
  //  border-color: -internal-light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3)) !important;
}

.accordion {
  // scss-docs-start accordion-css-vars
  --#{$prefix}accordion-color: #{$accordion-color};
  --#{$prefix}accordion-bg: #{$accordion-bg};
  --#{$prefix}accordion-transition: #{$accordion-transition};
  --#{$prefix}accordion-border-color: #{$accordion-border-color};
  --#{$prefix}accordion-border-width: #{$accordion-border-width};
  --#{$prefix}accordion-border-radius: #{$accordion-border-radius};
  --#{$prefix}accordion-inner-border-radius: #{$accordion-inner-border-radius};
  --#{$prefix}accordion-btn-padding-x: #{$accordion-button-padding-x};
  --#{$prefix}accordion-btn-padding-y: #{$accordion-button-padding-y};
  --#{$prefix}accordion-btn-color: #fff;
  --#{$prefix}accordion-btn-bg: #{$accordion-button-bg};

  --#{$prefix}accordion-btn-icon: #{url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")};
  --#{$prefix}accordion-btn-icon-width: #{$accordion-icon-width};
  --#{$prefix}accordion-btn-icon-transform: #{$accordion-icon-transform};
  --#{$prefix}accordion-btn-icon-transition: #{$accordion-icon-transition};
  --#{$prefix}accordion-btn-active-icon: #{url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")};
  --#{$prefix}accordion-btn-focus-border-color: #{$accordion-button-focus-border-color};
  --#{$prefix}accordion-btn-focus-box-shadow: #{$accordion-button-focus-box-shadow};
  --#{$prefix}accordion-body-padding-x: #{$accordion-body-padding-x};
  --#{$prefix}accordion-body-padding-y: #{$accordion-body-padding-y};
  --#{$prefix}accordion-active-color: #{$accordion-button-active-color};
  --#{$prefix}accordion-active-bg: #{$accordion-button-active-bg};
  // scss-docs-end accordion-css-vars
}

.pago {
  .pago-exitoso {
    .icono {
      font-size: 60px;
      color: green;
    }

    h5 {
      font-size: 18px;
    }

    span {
      font-weight: 600;
      line-height: 20px;
    }

    p {
      font-size: 16px;
      line-height: 18px;
    }

    .detalle {
      line-height: 20px;
    }
  }

  .pago-fallido {
    .icono {
      font-size: 60px;
      color: red;
    }

    h5 {
      font-size: 18px;
    }

    span {
      font-weight: 600;
    }

    p {
      font-size: 16px;
      line-height: 16px;
    }
  }

  h5 {
    color: #694b99;
  }

  h3 {
    color: #694b99;
    font-weight: 600;
  }
}

.accordion-item {
  color: white;
  background-color: transparent;
  border: none;
  border-bottom: none;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px;
  @include font-size($font-size-base);
  color: white;
  text-align: left; // Reset button style
  background: transparent;
  border: 0;
  @include border-radius(0);
  overflow-anchor: none;
  @include transition(var(--#{$prefix}accordion-transition));

  &:not(.collapsed) {
    // color: var(--#{$prefix}accordion-active-color);
    // background-color: var(--#{$prefix}accordion-active-bg);
    color: white;
    background: transparent;
    box-shadow: none;
    background-color: transparent;

    &::after {
      background-image: var(--#{$prefix}accordion-btn-active-icon);
      transform: var(--#{$prefix}accordion-btn-icon-transform);
    }
  }

  // Accordion icon
  &::after {
    flex-shrink: 0;
    width: var(--#{$prefix}accordion-btn-icon-width);
    height: var(--#{$prefix}accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--#{$prefix}accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--#{$prefix}accordion-btn-icon-width);
    //background-position: right;
    @include transition(var(--#{$prefix}accordion-btn-icon-transition));
  }

  &:hover {
    z-index: 2;
  }

  &:focus {
    z-index: 3;
    border: none;
    outline: 0;
    box-shadow: none;
  }
}

.iframe-quejas-y-reclamos {
  position: relative;
  top: -70px;
  z-index: 1;
}

.titulo-quejas {
  background-color: white;
  padding-top: 40px;
  padding-bottom: 40px;
  z-index: 9;
  position: relative;
}

.cartas-box {
  border-radius: 30px;
  border-color: darkgray;
  border-width: 1px;
  border-style: solid;
  padding-block: 20px;
}

.contenedor-categoria {
  position: relative;
  isolation: isolate;
  transition: all 0.5s ease-in-out 0s;

  //background: linear-gradient(to right, rgba(105, 75, 153, 0.4) 0%, rgba(105, 75, 153, 0.4) 33%, #fff 33%, #fff 100%);
  &:hover {
    cursor: pointer;
    //color: #fff;
    //border: 1px solid rgba(105, 75, 153, 0.4);
  }

  &::before,
  &::after {
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    content: "";
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &::after {
    right: 0;
    left: auto;

    width: 0;
    background: rgba(105, 75, 153, 0.4);
    //background: rgba(105, 75, 153, 0.4);
  }

  &:hover::after {
    right: auto;
    left: 0;

    width: 100%;
  }
}

#bonificacion-seleccion {
  color: #e58f2f !important;
  font-size: 14px;
  padding-bottom: 8px;
}

.shop-area {
  background-color: #f5f5f5;
}

.titulos-categoria {
  color: #694b99;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 4px;
}

.titulos-categoria-bajo {
  color: #694b99;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 4px;
}

.subtitulo-categoria {
  color: #694b99;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.fondo-gradiente {
  background: linear-gradient(to right, #3e6090, #704c9c) !important;

  /* Gradiente horizontal */
  .titulo {
    color: white !important;
  }
}

.box-carro {
  border-style: solid;
  border-width: 2px;
  border-radius: 30px;
  border-color: #694b99;
}

.box-direccion {
  border-radius: 25px;
  border-color: #694b99;
  border-style: solid;
  border-width: 2px;
  padding-inline: 20px;
  padding-block: 20px;
  margin: 2px;

  .principal {
    font-size: 18px;
    color: #694b99;
    font-weight: 600;
    line-height: 12px;
  }

  .secundaria {
    font-size: 16px;
    color: #694b99;
    font-weight: 500;
    line-height: 16px;
  }

  .terciario {
    font-size: 14px;
    color: #626262;
    font-weight: 500;
    line-height: 10px;
  }

  .predeterminado {
    font-size: 14px;
    color: #606975;
    font-weight: 500;
  }

  .check {
    align-self: center;
    font-size: xx-large;
  }
}

.seleccionada {
  border-color: #e58f2f !important;
}

.tooltip-inner {
  background-color: white;
  color: #000;
  padding-inline: 3px;
  padding-block: 3px;
  border-style: double;
  border-width: 2px;
  border-color: #e58f2f;
  border-radius: 10px;
}

.seguimiento {
  .text-box {
    font-size: 14px;
    height: 43px;
    padding: 2px 10px 2px 10px;
    color: #694b99;
    border: 1px solid #e58f2f;
    background: transparent none repeat scroll 0 0;
  }

  label {
    font-size: 16px;
    color: #694b99;
    font-weight: 500;
    line-height: 18px;
  }

  h1 {
    font-size: 26px;
    font-weight: 600;
    color: #694b99;
    padding-block: 10px;
  }

  h5 {
    font-size: 22px;
    font-weight: 600;
    color: #e58f2f;
    padding-block: 10px;
  }
}

.steps .step {
  display: block;
  width: 100%;
  margin-bottom: 35px;
  text-align: center;
}

.steps .step .step-icon-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 80px;
  text-align: center;
}

.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 5px;
  margin-top: -1px;
  background-color: #e1e7ec;
  content: "";
  z-index: 1;
}

.steps .step .step-icon-wrap::before {
  left: 0;
}

.steps .step .step-icon-wrap::after {
  right: 0;
}

.steps .step .step-icon {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  border: 1px solid #e1e7ec;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: #374250;
  font-size: 38px;
  line-height: 81px;
  z-index: 5;
}

.steps .step .step-title {
  margin-top: 16px;
  margin-bottom: 0;
  color: #606975;
  font-size: 14px;
  font-weight: 500;
}

.steps .step:first-child .step-icon-wrap::before {
  display: none;
}

.steps .step:last-child .step-icon-wrap::after {
  display: none;
}

.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
  background-color: #694b99;
}

.steps .step.completed .step-icon {
  border-color: #694b99;
  background-color: #694b99;
  color: #fff;
}

@media (max-width: 576px) {

  .flex-sm-nowrap .step .step-icon-wrap::before,
  .flex-sm-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 768px) {

  .flex-md-nowrap .step .step-icon-wrap::before,
  .flex-md-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 991px) {

  .flex-lg-nowrap .step .step-icon-wrap::before,
  .flex-lg-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 1200px) {

  .flex-xl-nowrap .step .step-icon-wrap::before,
  .flex-xl-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

.terminos {
  h1 {
    font-size: 26px;
    font-weight: 600;
    color: #694b99;
    padding-block: 10px;
  }

  h2 {
    font-size: 22px;
    font-weight: 600;
    color: #e58f2f;
    padding-block: 10px;
  }

  p {
    font-size: 16px;
    color: #694b99;
    font-weight: 500;
    line-height: 18px;
  }
}

.nuevo-paciente {
  .titulo {
    font-size: 20px;
    font-weight: 600;
    color: #694b99;
  }

  .sub-titulo {
    font-size: 18px;
    font-weight: 600;
    color: #694b99;

    margin-bottom: 0px;
  }

  label {
    font-size: 16px;
    font-weight: 600;
    color: #694b99;
    margin-bottom: 2px;
    padding-top: 5px;
  }

  input {
    border-radius: 25px;
    color: #694b99;
    font-size: 16px;
  }

  .boton {
    background-color: #694b99;
    padding-inline: 20px;
    padding-block: 10px;
    border-radius: 25px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin-inline: 20px;
    border-color: #694b99;
    border-style: none;

    &:hover {
      background-color: #e58f2f;
      border-color: #e58f2f;
    }
  }
}

.sin-cuenta {
  text-align: center;

  .titulo {
    font-size: 20px;
    font-weight: 600;
    color: #694b99;
  }

  .sub-titulo {
    font-size: 16px;
    font-weight: 600;
    color: #694b99;
    padding-top: 20px;
    margin-bottom: 2px;
  }

  .texto {
    font-size: 15px;
    font-weight: 500;
    color: #e58f2f;
  }

  .inicio {
    font-size: 15px;
    font-weight: 500;
    color: #694b99;
  }

  .boton-registro {
    background-color: #e58f2f;
    padding-inline: 20px;
    padding-block: 10px;
    border-radius: 25px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin-inline: 20px;
    border-color: #e58f2f;
    border-style: none;

    &:hover {
      background-color: #694b99;
      border-color: #694b99;
    }
  }

  .boton-login {
    background-color: #694b99;
    padding-inline: 20px;
    padding-block: 10px;
    border-radius: 25px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin-inline: 20px;
    border-color: #694b99;
    border-style: none;

    &:hover {
      background-color: #e58f2f;
      border-color: #e58f2f;
    }
  }
}

.seleccion {
  font-size: 15px;
  color: #694b99;
  font-weight: 500;

  .titulo {
    font-size: 22px;
    color: #e58f2f;
  }

  p {
    font-size: 16px;
    color: #545482 !important;
    font-weight: 600;
  }

  .precio-final {
    font-size: 18px;
    color: #e58f2f;
    font-weight: 600;
  }

  .product-quantity {
    width: 435px;

    .cart-plus-minus {
      position: relative;

      display: inline-block;

      width: 110px;
      height: 40px;
      padding: 0;

      .qtybutton {
        font-size: 16px;

        position: absolute;

        float: inherit;

        width: 22px;
        margin: 0;

        cursor: pointer;
        transition: all 0.3s ease 0s;
        text-align: center;

        color: #333;
        border: none;
        background: none;
      }

      .dec.qtybutton {
        top: 0;
        left: 0;

        height: 40px;
        color: #fff;
        background-color: #694b99;
        border-right: 1px solid #e5e5e5;

        &:hover {
          background-color: #e58f2f;
        }

        &:disabled {
          background-color: darkgray;
          border-radius: 0px;
          cursor: not-allowed;
        }
      }

      .inc.qtybutton {
        top: 0;
        right: 0;

        height: 40px;

        color: #fff;
        background-color: #694b99;

        border-left: 1px solid #e5e5e5;

        &:hover {
          background-color: #e58f2f;
        }

        &:disabled {
          cursor: not-allowed;
          background-color: darkgray;
          border-radius: 0px;
        }
      }

      input.cart-plus-minus-box {
        font-size: 18px;

        float: left;

        width: 110px;
        height: 40px;
        margin: 0;
        padding: 0;

        text-align: center;
        font-weight: 600;
        color: #694b99;
        border: 1px solid #e1e1e1;
        background: transparent none repeat scroll 0 0;
      }
    }
  }
}

.modal-modalidad {
  .modal-header {
    border-color: #e58f2f;
    border-bottom-width: 2px;
  }

  h5 {
    padding-top: 6px;
    color: #694b99;
    font-weight: 700;
    font-size: 18px;
  }

  p {
    color: #636d7b;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;

    margin-bottom: 8px;
  }

  .titulo {
    font-size: 18px;
    color: #694b99;
    font-weight: 600;
    margin-bottom: 15px;
  }

  span {
    font-weight: 600;
    line-height: 16px;
  }

  tr {
    border-bottom-style: dotted;
    border-bottom-width: 2px;
    border-bottom-color: #636d7b;
  }

  .form-switch {
    margin-bottom: 10px;
    padding-inline-start: 5px;

    .form-check-input {
      background-color: #fff;
      border-color: #e58f2f;
      font-size: 24px;
      background-position: left center;

      &:checked {
        background-color: #e58f2f;
        border-color: #e58f2f;
        background-position: right center;
      }
    }
  }
}

.check-modalidad-true {
  width: 60px;

  border-radius: 25px;
  border-width: 2px;
  border-style: solid;
  border-color: #e58f2f;

  align-content: center;
  font-size: 20px;
  display: block;
  padding-bottom: 1px;
  padding-top: 3px;
}

.check-modalidad-false {
  width: 60px;

  border-radius: 25px;
  border-width: 2px;
  border-style: solid;
  border-color: #e58f2f;

  align-content: center;
  display: block;
  font-size: 20px;
  padding-bottom: 1px;
  padding-top: 3px;
}

.boton {
  background-color: #694b99;
  padding-inline: 15px;
  padding-block: 5px;
  border-radius: 25px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  margin-inline: 10px;
  border-color: #694b99;
  border-style: none;

  &:hover {
    background-color: #e58f2f;
    border-color: #e58f2f;
  }

  &:disabled {
    background-color: #694b99;
    padding-inline: 15px;
    padding-block: 5px;
    border-radius: 25px;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    margin-inline: 10px;
    border-color: #694b99;
    border-style: none;
  }
}

.dropdown-custom {
  .dropdown {
    background-color: none !important;

    button {
      background-color: #e58f2f;
      border-color: #e58f2f;
      padding-block: 8px;
      padding-inline: 20px;
      border-radius: 25px;
      // &:hover {
      //   background-color: #694B99;
      //   color: #fff;
      // }

      &:hover {
        background-color: #694b99;
        border-color: #694b99;
      }

      &:active {
        background-color: #694b99;
        border-color: #694b99;
      }

      &:focus {
        background-color: #694b99;
        border-color: #694b99;
      }

      .show>&.dropdown-toggle {
        background-color: #694b99;
        border-color: #694b99;
      }
    }

    &:focus {
      background-color: #694b99;
      border-color: #694b99;
    }

    .show>&.dropdown-toggle {
      background-color: #694b99;
      border-color: #694b99;
    }
  }

  background-color: none !important;

  button {
    background-color: #e58f2f;
    border-color: #e58f2f;
    // &:hover {
    //   background-color: #694B99;
    //   color: #fff;
    // }

    &:hover,
    :active,
    :focus {
      background-color: #694b99;
      border-color: #694b99;
    }

    .show>&.dropdown-toggle {
      background-color: #694b99;
      border-color: #694b99;
    }
  }

  .dropdown-item {
    color: #694b99;
    font-weight: 500;

    a {
      color: #545482 !important;

      //background-color: #fff;
      &:active {
        background-color: #e58f2f;
        color: #fff !important;
      }

      &:focus {
        //background-color: #e58f2f;
        color: #fff !important;
      }

      &:visited {
        //background-color: #e58f2f;
        color: #545482 !important;
      }

      &:hover {
        // background-color: #e58f2f;
        color: #fff !important;
      }
    }

    &:hover,
    &:active,
    &:focus,
    &:visited {
      background-color: #e58f2f !important;
      color: #fff !important;
    }
  }
}

.dropdown-custom-modalidad {
  .dropdown-menu.show {
    width: 100%;
    padding: 0px;
  }

  .dropdown {
    background-color: none !important;

    button {
      background-color: white;
      border-color: lightgray;
      padding-block: 8px;
      padding-inline: 20px;
      border-radius: 25px;
      width: 100%;
      border-width: 2px;
      color: #694b99;
      font-weight: 500;
      // background-color: #e58f2f;
      // border-color: #e58f2f;
      // padding-block: 8px;
      // padding-inline: 20px;
      // border-radius: 25px;
      // width: 100%;
      //text-align: left;
      // &:hover {
      //   background-color: #694B99;
      //   color: #fff;
      // }

      &:hover {
        background-color: #694b99;
        border-color: #694b99;
        color: #fff;

      }

      &:active {
        background-color: #694b99;
        border-color: #694b99;
        color: #fff;
      }

      &:focus {
        background-color: #694b99;
        border-color: #694b99;
        color: #fff;
      }

      .show>&.dropdown-toggle {
        background-color: #694b99;
        border-color: #694b99;
      }
    }

    &:focus {
      background-color: #694b99;
      border-color: #694b99;
    }

    .show>&.dropdown-toggle {
      background-color: #694b99;
      border-color: #694b99;
    }
  }

  background-color: none !important;

  button {
    background-color: #e58f2f;
    border-color: #e58f2f;
    // &:hover {
    //   background-color: #694B99;
    //   color: #fff;
    // }

    &:hover,
    :active,
    :focus {
      background-color: #694b99;
      border-color: #694b99;
    }

    .show>&.dropdown-toggle {
      background-color: #694b99;
      border-color: #694b99;
    }
  }

  .dropdown-item {
    color: #694b99;
    font-weight: 500;
    text-align: center;
    border-bottom: gray;
    border-top: ridge;

    a {
      color: #545482 !important;

      //background-color: #fff;
      &:active {
        background-color: #e58f2f;
        color: #fff !important;
      }

      &:focus {
        //background-color: #e58f2f;
        color: #fff !important;
      }

      &:visited {
        //background-color: #e58f2f;
        color: #545482 !important;
      }

      &:hover {
        // background-color: #e58f2f;
        color: #fff !important;
      }
    }

    &:hover,
    &:active,
    &:focus,
    &:visited {
      background-color: #e58f2f !important;
      color: #fff !important;
    }
  }
}

.buscador-superior {
  .wrapper {
    margin: 0px !important;

    //min-height: 30px !important;
    .input {
      height: 28px;
    }
  }
}

.p-bioeq {
  font-size: 18px;
  color: #694b99;
  font-weight: 500;
}

.h3-categoria {
  color: #694b99;
  font-size: 24px;
  font-weight: 500;
}

.banner-home {
  //padding-top: 30px;

  .interior {
    //margin-block: 14px;
    padding-block: 20px;
    //padding-inline: 4px;

    fill: #694b99;

    a {
      color: #694b99;
    }

    .icon-box {
      //background-color: #694B99;
      //padding: 10px;
      border-radius: 50%;
      width: 70px;
      height: 70px;
      display: flex;
    }

    .text-box {
      padding-left: 15px;
      padding-right: 5px;

      h5 {
        font-weight: bold;
        font-size: 14px;
        color: #694b99;
        // font-size: medium;
      }

      p {
        color: #694b99;
        font-size: 13px;
        line-height: 18px;
      }
    }
  }

  a {
    font-size: 18px;
    // color: #694B99;
    font-weight: 500;
    text-align: start;
    // &:hover {
    //   color: #fff;
    // }
    //padding-inline-end: 10px;
  }
}

.info-liga {
  background-color: #ecebf0;

  .texto-interior {
    padding-block: 40px;
    padding-inline: 30px;

    h1 {
      font-size: 36px;
      font-weight: 600;
      color: #fff;
      padding-bottom: 20px;
    }

    p {
      padding-top: 0px;
      //text-align: justify;
      font-size: 16px;
      //color: #694b99;
      font-weight: lighter;
      //padding-bottom: 20px;
      padding-right: 20px;
      line-height: 22px;
    }

    .btn-liga {
      background-color: #fff;
      border-radius: 12px;
      color: #694b99;
      font-size: 14px;
      padding-block: 10px;
      padding-inline: 16px;
      font-weight: 500;
    }
  }
}

.regulatorios {
  .titulo-con-linea {
    padding-inline: 40px;
    position: relative;
  }

  .regulatorios-box {
    background-color: #694b99;
    border-radius: 30px;
    padding-block: 20px;
    padding-inline: 30px;

    a {
      color: #fff;
      font-size: 13px;
      font-size: medium;
    }
  }

  .titulo-con-linea::before,
  .titulo-con-linea::after {
    content: "";
    position: absolute;
    top: 50%;
    /* Coloca la línea en el centro vertical del texto */
    height: 2px;
    /* Altura de la línea decorativa */
    width: 50px;
    /* Longitud de la línea decorativa */
    background-color: #333;
    /* Color de la línea decorativa */
  }

  .titulo-con-linea::before {
    left: 0px;
    /* Coloca la línea antes del texto */
  }

  .titulo-con-linea::after {
    right: 0px;
    /* Coloca la línea después del texto */
  }

  h2 {
    font-size: 32px;
    font-weight: 600;
    color: #694b99;
    padding-bottom: 30px;
  }

  text-align: center;
  padding-top: 60px;

  .imagen {
    margin-block: 20px;
    padding-inline: 10px;
    overflow: hidden;

    img {
      width: 100%;
      border-radius: 30px;
    }
  }

  .seccion-cenabast {
    padding-block: 30px;
    border-radius: 30px;
    border-width: 1px;
    border-color: #bcbcbc;
    border-style: solid;
    padding-block: 30px;

    .cenabast {
      width: 70%;
    }

    .cenabast-texto {
      padding-top: 4px;
      text-align: center;
      font-size: 16px;
      color: #694b99;
      font-weight: 500;
    }
  }
}

.sedes {
  .interior {
    padding-inline-start: 5px;
    padding-inline-end: 15px;
  }

  .titulo {
    font-size: 22px;
    color: #e58f2f;
    padding-block: 10px;
  }

  .datos {
    color: #694b99;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
  }

  span {
    font-weight: 600;
  }
}

html,
body {
  height: 100%;
}

body {
  font-family: $poppins;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 24px;

  color: #694b99;
  background-color: $white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $poppins;
  font-weight: 400;

  margin-top: 0;

  color: $heading-color;
}

p {
  font-family: $poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;

  margin-bottom: 15px;

  color: #694b99;
}

h1 {
  font-size: 36px;
  line-height: 42px;
}

h2 {
  font-size: 30px;
  line-height: 36px;
}

h3 {
  font-size: 24px;
  line-height: 30px;
}

h4 {
  font-size: 18px;
  line-height: 24px;
}

h5 {
  font-size: 14px;
  line-height: 18px;
}

h6 {
  font-size: 12px;
  line-height: 14px;
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  line-height: inherit;

  cursor: pointer;
  text-decoration: none;

  color: #694b99;
}

a,
button,
img,
input,
span {
  transition: all 0.3s ease 0s;
}

*:focus {
  outline: none !important;
}

a:focus {
  text-decoration: none;

  color: inherit;
  outline: none;
}

a:hover {
  text-decoration: none;
}

button,
input[type="submit"] {
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;

  list-style: outside none none;
}

/*-- 
    - Common Classes
-----------------------------------------*/

.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.section {
  float: left;

  width: 100%;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.bg-img {
  background-position: center center;
  background-size: cover;
}

.border-bottom-1 {
  border-bottom: 1px solid #d5d5d5;
}

a:hover {
  color: $theme-color;
}

/*************************
  Basic margin padding
*************************/

.m-0 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.p-0 {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

/*************************
         Margin top
*************************/

.mt-0 {
  margin-top: 0;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;

  @media #{$xs-layout} {
    margin-top: 60px;
  }
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

/*************************
      Margin right
*************************/

.mr-0 {
  margin-right: 0;
}

.mr-10 {
  margin-right: 10px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-150 {
  margin-right: 150px;
}

/*************************
      Margin bottom
*************************/

.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;

  @media #{$xs-layout} {
    margin-bottom: 60px;
  }
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;

  @media #{$xs-layout} {
    margin-bottom: 50px;
  }
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-150 {
  margin-bottom: 150px;
}

/*************************
        Margin left
*************************/

.ml-0 {
  margin-left: 0;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-ntv5 {
  margin-left: -5px;
}

/*************************
        Padding top
*************************/

.pt-0 {
  padding-top: 0;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-70 {
  padding-top: 70px;

  @media #{$xs-layout} {
    padding-top: 60px;
  }
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;

  @media #{$xs-layout} {
    padding-top: 60px;
  }
}

.pt-95 {
  padding-top: 95px;

  @media #{$xs-layout} {
    padding-top: 55px;
  }
}

.pt-90 {
  padding-top: 90px;

  @media #{$xs-layout} {
    padding-top: 50px;
  }
}

.pt-100 {
  padding-top: 100px;

  @media #{$xs-layout} {
    padding-top: 60px;
  }
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;

  @media #{$xs-layout} {
    padding-top: 40px;
  }
}

.pt-115 {
  padding-top: 115px;

  @media #{$xs-layout} {
    padding-top: 60px;
  }
}

.pt-120 {
  padding-top: 120px;

  @media #{$xs-layout} {
    padding-top: 50px;
  }
}

.pt-125 {
  padding-top: 125px;

  @media #{$xs-layout} {
    padding-top: 55px;
  }

  @media #{$md-layout} {
    padding-top: 75px;
  }
}

.pt-128 {
  padding-top: 128px;
}

.pt-130 {
  padding-top: 130px;

  @media #{$xs-layout} {
    padding-top: 60px;
  }

  @media #{$md-layout} {
    padding-top: 80px;
  }
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-180 {
  padding-top: 180px;

  @media #{$xs-layout} {
    padding-top: 60px;
  }
}

.pt-205 {
  padding-top: 205px;
}

.pt-230 {
  padding-top: 230px;
}

.pt-270 {
  padding-top: 270px;

  @media #{$md-layout} {
    padding-top: 170px;
  }

  @media #{$xs-layout} {
    padding-top: 100px;
  }
}

.pt-240 {
  padding-top: 240px;
}

.pt-365 {
  padding-top: 365px;
}

.pt-370 {
  padding-top: 370px;
}

.pt-380 {
  padding-top: 380px;
}

/*************************
        Padding right
*************************/

.pr-0 {
  padding-right: 0;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-270 {
  padding-right: 270px;
}

/*************************
        Padding bottom
*************************/

.pb-0 {
  padding-bottom: 0;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;

  @media #{$xs-layout} {
    padding-bottom: 15px;
  }
}

.pb-60 {
  padding-bottom: 60px;

  @media #{$xs-layout} {
    padding-bottom: 20px;
  }
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;

  @media #{$xs-layout} {
    padding-bottom: 30px;
  }
}

.pb-80 {
  padding-bottom: 80px;

  @media #{$xs-layout} {
    padding-bottom: 40px;
  }
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;

  @media #{$xs-layout} {
    padding-bottom: 50px;
  }
}

.pb-95 {
  padding-bottom: 95px;

  @media #{$xs-layout} {
    padding-bottom: 55px;
  }
}

.pb-100 {
  padding-bottom: 100px;

  @media #{$xs-layout} {
    padding-bottom: 30px;
  }
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;

  @media #{$xs-layout} {
    padding-bottom: 60px;
  }
}

.pb-120 {
  padding-bottom: 120px;

  @media #{$xs-layout} {
    padding-bottom: 50px;
  }
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;

  @media #{$xs-layout} {
    padding-bottom: 60px;
  }

  @media #{$md-layout} {
    padding-bottom: 80px;
  }
}

.pb-140 {
  padding-bottom: 140px;

  @media #{$xs-layout} {
    padding-bottom: 60px;
  }
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-280 {
  padding-bottom: 280px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-285 {
  padding-bottom: 285px;
}

.pb-270 {
  padding-bottom: 270px;

  @media #{$md-layout} {
    padding-bottom: 170px;
  }

  @media #{$xs-layout} {
    padding-bottom: 100px;
  }
}

.pb-290 {
  padding-bottom: 290px;
}

/*************************
        Padding left
*************************/

.pl-0 {
  padding-left: 0;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-190 {
  padding-left: 190px;
}

/***************************
    Page section padding 
****************************/

.ptb-0 {
  padding: 0;
}

.ptb-10 {
  padding: 10px 0;
}

.ptb-20 {
  padding: 20px 0;
}

.ptb-30 {
  padding: 30px 0;
}

.ptb-35 {
  padding: 35px 0;
}

.ptb-32 {
  padding: 32px 0;
}

.ptb-40 {
  padding: 40px 0;
}

.ptb-50 {
  padding: 50px 0;
}

.ptb-60 {
  padding: 60px 0;
}

.ptb-70 {
  padding: 70px 0;
}

.ptb-80 {
  padding: 80px 0;
}

.ptb-90 {
  padding: 90px 0;
}

.ptb-100 {
  padding: 100px 0;
}

.ptb-110 {
  padding: 110px 0;
}

.ptb-120 {
  padding: 120px 0;
}

.ptb-130 {
  padding: 130px 0;
}

.ptb-140 {
  padding: 140px 0;
}

.ptb-150 {
  padding: 150px 0;
}

.ptb-160 {
  padding: 160px 0;
}

.ptb-170 {
  padding: 170px 0;
}

.ptb-177 {
  padding: 177px 0;
}

.ptb-180 {
  padding: 180px 0;
}

.ptb-190 {
  padding: 190px 0;
}

.ptb-200 {
  padding: 200px 0;
}

.ptb-210 {
  padding: 210px 0;
}

.ptb-220 {
  padding: 220px 0;
}

.ptb-290 {
  padding: 290px 0;
}

.ptb-310 {
  padding: 310px 0;
}

/***************************
    Page section margin 
****************************/

.mtb-0 {
  margin: 0;
}

.mtb-10 {
  margin: 10px 0;
}

.mtb-15 {
  margin: 15px 0;
}

.mtb-20 {
  margin: 20px 0;
}

.mtb-30 {
  margin: 30px 0;
}

.mtb-40 {
  margin: 40px 0;
}

.mtb-50 {
  margin: 50px 0;
}

.mtb-60 {
  margin: 60px 0;
}

.mtb-70 {
  margin: 70px 0;
}

.mtb-80 {
  margin: 80px 0;
}

.mtb-90 {
  margin: 90px 0;
}

.mtb-100 {
  margin: 100px 0;
}

.mtb-110 {
  margin: 110px 0;
}

.mtb-120 {
  margin: 120px 0;
}

.mtb-130 {
  margin: 130px 0;
}

.mtb-140 {
  margin: 140px 0;
}

.mtb-150 {
  margin: 150px 0;
}

.mtb-290 {
  margin: 290px 0;
}

.mb--10 {
  margin-bottom: -10px;
}

/*-- 
    - Input Placeholder
-----------------------------------------*/

input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input {
  font-size: 14px;

  width: 100%;
  //height: 45px;
  padding-left: 10px;
  font-weight: 500;
  color: #694b99;
  border: 2px solid #eceff8;
  background: #eceff8;
  box-shadow: none;
}

select {
  font-size: 14px;

  width: 100%;
  font-weight: 500;
  color: #694b99;
  border: 0 solid #eceff8;
  background: transparent;
  box-shadow: none;
}

option {
  font-size: 14px;

  padding-left: 10px;

  border: 0 solid #626262;
  background: #fff;
}

input:focus {
  background: transparent;
}

textarea {
  font-size: 14px;

  width: 100%;
  padding: 10px;

  resize: vertical;

  border: 2px solid #eceff8;
  background: #eceff8;
}

textarea:focus {
  border: 2px solid #4fc1f0;
  outline: none;
  background: transparent;
}

.input-group.divcenter.input-group .form-control {
  padding-left: 0;
}

.custom-row {
  display: flex;
  flex-wrap: wrap;

  margin-right: -15px;
  margin-left: -15px;

  @media #{$xl-layout} {
    margin-right: -15px;
    margin-left: -15px;
  }

  @media #{$lg-layout} {
    margin-right: -15px;
    margin-left: -15px;
  }

  @media #{$md-layout} {
    margin-right: -15px;
    margin-left: -15px;
  }

  @media #{$xs-layout} {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.custom-row-2 {
  display: flex;
  flex-wrap: wrap;

  margin-right: -5px;
  margin-left: -5px;

  & div[class^="col-"] {
    padding-right: 5px;
    padding-left: 5px;

    @media #{$md-layout} {
      padding-right: 15px;
      padding-left: 15px;
    }

    @media #{$xs-layout} {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  @media #{$xl-layout} {
    margin-right: -5px;
    margin-left: -5px;
  }

  @media #{$lg-layout} {
    margin-right: -5px;
    margin-left: -5px;
  }

  @media #{$md-layout} {
    margin-right: -15px;
    margin-left: -15px;
  }

  @media #{$xs-layout} {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.custom-row-3 {
  display: flex;
  flex-wrap: wrap;

  margin-right: -13.6px;
  margin-left: -13.6px;

  & div[class^="col-"] {
    padding-right: 13.6px;
    padding-left: 13.6px;

    @media #{$xs-layout} {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  @media #{$xl-layout} {
    margin-right: -13.6px;
    margin-left: -13.6px;
  }

  @media #{$lg-layout} {
    margin-right: -13.6px;
    margin-left: -13.6px;
  }

  @media #{$md-layout} {
    margin-right: -13.6px;
    margin-left: -13.6px;
  }

  @media #{$xs-layout} {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.custom-col-5 {
  position: relative;

  flex: 0 0 20%;

  width: 100%;
  max-width: 20%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  @media #{$xl-layout} {
    padding-right: 15px;
    padding-left: 15px;
  }

  @media #{$lg-layout} {
    flex: 0 0 33.333%;

    max-width: 33.333%;
    padding-right: 15px;
    padding-left: 15px;
  }

  @media #{$md-layout} {
    flex: 0 0 50%;

    max-width: 50%;
    padding-right: 15px;
    padding-left: 15px;
  }

  @media #{$xs-layout} {
    flex: 0 0 100%;

    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  @media #{$sm-layout} {
    flex: 0 0 50%;

    max-width: 50%;
  }
}

.mobile-menu-area {
  display: none;

  @media #{$md-layout} {
    display: block;
  }

  @media #{$xs-layout} {
    display: block;
  }

  @media #{$sm-layout} {
    display: block;
  }

  &.white-mobile-menu {
    a.meanmenu-reveal {
      color: #fff;
      border: 1px solid #fff;

      span {
        background-color: #fff;
      }
    }
  }
}

.stick {
  .mobile-menu-area {
    &.white-mobile-menu {
      a.meanmenu-reveal {
        color: #333;
        border: 1px solid #333;

        span {
          background-color: #333;
        }
      }
    }
  }
}

.mean-container a.meanmenu-reveal {
  top: -30px;
}

#mobile-menu-active {
  display: none;
}

.mobile-menu .mean-nav ul.menu-overflow {
  overflow-y: auto;

  height: 215px;
  margin-top: 10;
}

.mobile-menu.mean-container .mean-nav ul {
  margin: 14px 0 0;
}

/*-- 
    Scroll Up 
-----------------------------------------*/

.scroll-top {
  position: fixed;
  z-index: 9811 !important;
  right: 20px;
  bottom: 60px;

  // visibility: hidden;
  overflow: hidden;

  width: 50px;
  height: 50px;

  text-align: center;

  // opacity: 0;
  color: #fff;
  border: none;
  border-radius: 50px;
  background: none;
  background-color: $theme-color;
  // &.show {
  //   visibility: visible;

  //   opacity: 1;
  // }
  @media #{$xs-layout} {
    width: 40px;
    height: 40px;
  }

  & i {
    font-size: 22px;
    line-height: 50px;

    display: block;

    @media #{$xs-layout} {
      font-size: 16px;
      line-height: 40px;
    }
  }

  &:hover {
    & i {
      animation-name: fadeInUp;
      animation-duration: 1s;
      animation-iteration-count: infinite;

      animation-fill-mode: both;
    }
  }
}

.height-100 {
  height: 100vh;
}

/*--
    - Background Color
------------------------------------------*/

.bg-purple {
  background-color: #f0e0ff;
}

.bg-purple-2 {
  background-color: #cea1f8;
}

.bg-gray {
  background-color: #f6f6f8;
}

.bg-gray-2 {
  background-color: #f6f6f6;
}

.bg-gray-3 {
  background-color: #f7f7f7;
}

.bg-gray-4 {
  background-color: #f1f1f1;
}

.bg-gray-5 {
  background-color: #f9f9f9;
}

.bg-gray-6 {
  background-color: #e9e6e9;
}

.bg-gray-7 {
  background-color: #f8f8f8;
}

.bg-black {
  background-color: #000000;
}

.bg-glaucous {
  background-color: #daedff;
}

.bg-aqua {
  background-color: #dbfffb;
}

.bg-white {
  background-color: #fff;
}

.default-overlay {
  position: relative;

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    content: "";
  }
}

.btn-hover {

  a,
  button {
    position: relative;
    isolation: isolate;
    transition: all 0.5s ease-in-out 0s;

    &:hover {
      color: #fff;
      border: 1px solid $theme-color;
    }

    &::before,
    &::after {
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 100%;

      content: "";
      transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &::after {
      right: 0;
      left: auto;

      width: 0;

      background: $theme-color;
    }

    &:hover::after {
      right: auto;
      left: 0;

      width: 100%;
    }
  }

  &--blue {

    a,
    button {
      &:hover {
        border-color: #053399 !important;
      }

      &::after {
        background: #053399;
      }
    }
  }
}

a.default-btn {
  font-size: 16px;
  line-height: 1;

  display: inline-block;

  padding: 19px 50px 21px;

  text-transform: uppercase;

  color: #010101;
  border: 1px solid #333;
}

/* slider img bounce */

@-webkit-keyframes bounce-img {
  0% {
    transform: translateY(0);

    opacity: 1;
  }

  50% {
    transform: translateY(20px);

    opacity: 1;
  }

  100% {
    transform: translateY(0);

    opacity: 1;
  }
}

@keyframes bounce-img {
  0% {
    transform: translateY(0);

    opacity: 1;
  }

  50% {
    transform: translateY(20px);

    opacity: 1;
  }

  100% {
    transform: translateY(0);

    opacity: 1;
  }
}

@keyframes snow {
  0% {
    background-position: 0 0, 0 0, 0 0;
  }

  100% {
    background-position: 500px 1000px, 400px 400px, 300px 300px;
  }
}

.effect-snow {
  animation: snow 20s linear infinite;
}

.body-effect {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0);
}

.padding-10-row-col {
  .row {
    margin-right: -5px;
    margin-left: -5px;

    div[class^="col-"] {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
}

.padding-20-row-col {
  .row {
    margin-right: -10px;
    margin-left: -10px;

    div[class^="col-"] {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}

[class*="ToastContainer"] {
  overflow: hidden !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.item-empty-area {
  &__icon {
    i {
      font-size: 140px;
      line-height: 1;
      font-weight: 600;

      color: #694b99;

      @media #{$xs-layout} {
        font-size: 100px;
      }
    }
  }

  &__text {
    font-size: 20px;
    font-weight: 500;
    color: #694b99;

    a {
      font-size: 14px;
      font-weight: 500;

      display: inline-block;

      margin-top: 30px;
      padding: 10px 30px;

      transition: 0.3s;

      color: $white;
      background-color: #694b99;

      &:hover {
        color: $white;
        background-color: #e58f2f;
      }
    }
  }
}

.fullpage-slider-wrapper {
  .Navigation {
    .Navigation-Anchor {
      background-color: #333 !important;
    }
  }
}

.bg-white {
  background-color: $white;
}

.rounden-btn {
  overflow: hidden;

  border-radius: 50px;

  &:before,
  &:after {
    border-radius: 50px;
  }
}

.bg-black-2 {
  background-color: #1b1b1b;
}

.bg-black-3 {
  background-color: #202022;
}

.bg-cover {
  background-size: cover;
}

.product-small-image-wrapper.product-small-image-wrapper--side-thumb .swiper-wrap,
.swiper-pointer-events.swiper-vertical {
  height: 100%;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0);
  }
}

.map-marker {
  animation-name: bounce;
  animation-timing-function: linear;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

button:disabled {
  cursor: not-allowed;
  padding-block: 10px;
  //border: none;
  border-radius: 15px;
  font-size: 13px;
  padding-inline: 2px;
  font-weight: 600;
  margin-inline: 2px;
  border: none;
  background-color: transparent;

}

.swiper-wrap {
  position: relative;
}