/*------ 16. Breadcrumb style  --------*/

// .breadcrumb-content {
//   ul {
//     li {
//       display: inline-block;
//       font-size: 15px;
//       font-weight: 500;
//       color: #5b5858;
//       margin-right: 18px;
//       text-transform: uppercase;
//       position: relative;
//       &::before {
//         position: absolute;
//         width: 18px;
//         height: 1px;
//         background-color: #5b5858;
//         content: "";
//         right: -21px;
//         top: 12px;
//         z-index: 99;
//         transform: rotate(115deg);
//       }
//       &:last-child::before {
//         display: none;
//       }
//       a {
//         color: #5b5858;
//         &:hover {
//           color: $theme-color;
//         }
//       }
//     }
//   }
// }


.breadcrumb {
  --bs-breadcrumb-margin-bottom: 0;
  --bs-breadcrumb-divider-color: #4c4c73 ;
  --bs-breadcrumb-item-active-color: #4c4c73  ;
  --bs-breadcrumb-item-padding-x: 5px;
  justify-content: flex-start;
  &-item {
    color: #5b5858;
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    //text-transform: uppercase;
    a{
      color: inherit;
    }
  }
}

.breadcrumb-area{
  background-color: #ececf4  ;
  .area-left{
    display: flex;
    text-align: start;
    .modalidad{
      color: #646b7b  ;
      font-size: 16px;
      font-weight: 500;
      .seleccionada{
        color: #4c4c73  ;
        padding-left: 10px;
      }
    }
    
  }
  .area-right{
    text-align: end;
    justify-content: center;
    .boton{
      background-color: #694B99;
      padding-block: 10px;
      padding-inline: 20px;
      color: #fff;
      border-radius: 15px;
      font-size: 15px;
      font-weight: 400;
      box-shadow: 3px 3px 5px darkgray;
      &:hover{
        background-color: #fff  ;
        border-color: #fff;
        color: #694B99;
      }
    }
  }
}